import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Calendar from 'react-calendar';

function DateTime(props) {
  const { registerField, unregisterField } = useFormikContext();
  const { onChange, value, name, label, errors, touched, validate } = props;
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 3);

  useEffect(() => {
    registerField(name, { validate });
    return () => {
      unregisterField(name);
    };
  }, [registerField, unregisterField, name]);

  return (
    <div className="form__field">
      <label className="form__label" htmlFor={name}>{label}</label>
      <Calendar
        onChange={(date) => { onChange(name, date); }}
        value={value}
        minDate={minDate}
      />
      {errors[name] && touched[name] && <div className="form__error">{errors[name]}</div>}
    </div>
  );
}

DateTime.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  validate: PropTypes.func,
};

DateTime.defaultProps = {
  value: null,
  validate: null,
};

export default DateTime;
