import PropTypes from 'prop-types';

export default {
  meatDishes: PropTypes.arrayOf(PropTypes.string).isRequired,
  sideDishes: PropTypes.arrayOf(PropTypes.string).isRequired,
  salads: PropTypes.arrayOf(PropTypes.string).isRequired,
  fingerFood: PropTypes.arrayOf(PropTypes.string).isRequired,
  soups: PropTypes.arrayOf(PropTypes.string).isRequired,
  desserts: PropTypes.arrayOf(PropTypes.string).isRequired,
  persons: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  date: PropTypes.instanceOf(Date),
  delivery: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
};
