import React from 'react';
import PropTypes from 'prop-types';
import formValueShape from '../../container/MenuForm/shapes';
// import { MEAT_DISHES, SIDE_DISHES, SALADS, FINGERFOOD, SOUPS, DESSERTS } from '../../constants/dishes';
import MenuSelect from '../Fields/MenuSelect/MenuSelect';
import useDishes from '../../hooks/dishes';

function StepMenu({
  setFieldValue,
  values,
  isSubmitting,
}) {
  const [dishes] = useDishes();

  if (!dishes) {
    return null;
  }

  return (
    <>
      <MenuSelect
        name="meatDishes"
        onChange={setFieldValue}
        value={values.meatDishes}
        dishes={dishes.fleischgerichte}
        lables={{
          singular: 'Fleischgericht',
          majority: 'Fleischgerichte',
        }}
      />
      <MenuSelect
        name="barbecue"
        onChange={setFieldValue}
        value={values.barbecue}
        dishes={dishes.barbecue}
        lables={{
          singular: 'Barbecue',
          majority: 'Barbecue',
        }}
      />
      <MenuSelect
        name="sideDishes"
        onChange={setFieldValue}
        value={values.sideDishes}
        dishes={dishes.beilagen}
        lables={{
          singular: 'Beilage',
          majority: 'Beilagen',
        }}
      />
      <MenuSelect
        name="salads"
        onChange={setFieldValue}
        value={values.salads}
        dishes={dishes.salate}
        lables={{
          singular: 'Salat',
          majority: 'Salate',
        }}
      />
      <MenuSelect
        name="fingerFood"
        onChange={setFieldValue}
        value={values.fingerFood}
        dishes={dishes.fingerfoodUndKaltePlatten}
        lables={{
          singular: 'Fingerfood',
          majority: 'Fingerfood und kalte Platten',
        }}
      />
      <MenuSelect
        name="soups"
        onChange={setFieldValue}
        value={values.soups}
        dishes={dishes.suppen}
        lables={{
          singular: 'Suppe',
          majority: 'Suppen',
        }}
      />
      <MenuSelect
        name="desserts"
        onChange={setFieldValue}
        value={values.desserts}
        dishes={dishes.nachtische}
        lables={{
          singular: 'Nachtisch',
          majority: 'Nachtische',
        }}
      />

      <div className="form__controls">
        <button aria-label="Weiter zu den Veranstaltungsdaten" className="button" type="submit" disabled={isSubmitting}>
          Weiter zu den Veranstaltungsdaten
        </button>
      </div>
    </>
  );
}

StepMenu.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape(formValueShape).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

// StepMenu.defaultProps = {};

export default StepMenu;
