import React from 'react';
import PropTypes from 'prop-types';
import './online-menue-planer.scss';
import SEO from '../container/Seo/Seo';
import Layout from '../presentational/Layout/Layout';
import Banner from '../presentational/Banner/Banner';
import FormSteps from '../presentational/FormSteps/FormSteps';
import MenuForm from '../container/MenuForm/MenuForm';

const scroll = (id) => (e) => {
  e.preventDefault();
  const element = document.getElementById(id);
  const offsetPosition = element.offsetTop - 110;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

function OnlineMenuPlanner({ location }) {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title="Online Menü-Planer"
        description="Klicken Sie schnell und einfach Ihr unverbindliches Catering-Wunschmenü zusammen. Wählen Sie aus Fleischgerichten, Beilagen, Fingerfood und vielem anderen."
      />
      <Banner />

      <section className="section">
        <div className="container">
          <h1>Konfigurieren Sie jetzt Ihr Menü online</h1>
          <div className="row">
            <div className="col col--lg-8">
              <p>
                Klicken Sie schnell und einfach Ihr Catering-Wunschmenü zusammen. Nach dem Abschicken
                erstellen wir ein Angebot und setzen uns mit Ihnen in Verbindung.
                Alles natürlich vollkommen unverbindlich.<br />
                Brauchen Sie Hilfe?
                Hier finden Sie eine kurze <a href="#manual" onClick={scroll('manual')}>Anleitung</a>.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="menuFormSection" className="section">
        <div className="container">
          <FormSteps
            steps={[
              { id: 'MENU', label: 'Menü Auswahl' },
              { id: 'EVENT_DATA', label: 'Veranstaltungsdaten' },
              { id: 'PERSONAL_DATA', label: 'Persönliche Daten' },
            ]}
          />
          <MenuForm />
        </div>
      </section>

      <section className="section" id="manual">
        <div className="container">
          <h2>Anleitung zum Erstellen Ihres Wunschmenüs</h2>
          <p>
            Wählen Sie aus den Kategorien: <strong>Fleischgerichte</strong>, <strong>Beilagen</strong>, <strong>Salate</strong>, <strong>Fingerfood und kalte Platten</strong>, <strong>Suppen</strong> und <strong>Nachtische</strong>, Ihre Wunschgerichte.
            Klicken Sie dazu auf das entsprechende Gericht. Um es wieder zu entfernen, klicken Sie erneut.
          </p>
          <p>
            Nachdem Sie alle gewünschten Gerichte ausgewählt haben, benötigen wir noch ein paar weitere Informationen zu
            der Veranstaltung. Klicken Sie dafür am Ende des Formulars auf „Weiter zu den Veranstaltungsdaten“.
            Bitte geben Sie an: Wie viele Personen erwarten Sie?; Wann findet die Veranstaltung statt?; Brauchen Sie auch Besteck und Geschirr?;
            Wie kommt das Essen zu Ihnen?; Haben Sie noch Anmerkungen?
          </p>
          <p>
            Zum Schluss benötigen wir noch ein paar persönliche Angaben, um uns mit Ihnen in Verbindung setzen zu können.
            Klicken Sie dazu auf „Weiter zu den persönlichen Daten“. Geben Sie anschließend bitte folgende Daten an:
            Vor- und Nachname; Straße und Hausnummer; Postleitzahl und Ort; E-Mail Adresse; Telefon oder Mobil-Telefon (optional);.
            Ihre Daten werden vertraulich behandelt, nicht an Dritte gegeben und auch nicht zu Werbezwecken genutzt.
          </p>
          <p>
            Durch Klick auf „Unverbindlich Abschicken“, wird Ihre Anfrage an uns geschickt. Anschließend setzen wir uns
            sobald wie möglich mit Ihnen in Verbindung.
          </p>
        </div>
      </section>
    </Layout>
  );
}

OnlineMenuPlanner.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default OnlineMenuPlanner;
