import React from 'react';
import PropTypes from 'prop-types';
import { validateEmail, validateMinLength, validateRequired } from '../../container/MenuForm/validators';
import formValueShape from '../../container/MenuForm/shapes';
import Text from '../Fields/Text';
import Checkbox from '../Fields/Checkbox';

function StepPersonalData(props) {
  const { isSubmitting, stepBack, setFieldValue, values } = props;
  return (
    <>
      <Text
        {...props}
        name="name"
        label="Vor- und Nachname"
        validate={validateMinLength('Bitte geben Sie Ihren Vor- und Nachnamen ein', 4)}
      />

      <Text
        {...props}
        name="street"
        label="Straße und Hausnummer"
        validate={validateMinLength('Bitte geben Sie Ihre Straße und Hausnummer ein', 4)}
      />

      <Text
        {...props}
        name="zip"
        label="Postleitzahl und Ort"
        validate={validateMinLength('Bitte geben Sie Ihre Postleitzahl und den Ort ein', 7)}
      />

      <Text
        {...props}
        name="email"
        type="email"
        label="E-Mail Adresse"
        validate={validateEmail('Bitte geben Sie Ihre E-Mail Adresse ein')}
      />

      <Text
        {...props}
        name="phone"
        type="tel"
        label="Telefon oder Mobil-Telefon (optional)"
      />

      <Checkbox
        {...props}
        name="datenschutz"
        onChange={setFieldValue}
        value={values.datenschutz}
        label={(<span>Ich habe die <a target="_blank" href="/datenschutz">Datenschutzinformationen</a> zur Kenntnis genommen und bin einverstanden.</span>)}
        validate={validateRequired('Bitte stimmen sie den Datenschutzinformationen zu')}
      />

      <div className="form__controls form__controls--space">
        <button aria-label="Unverbindlich Abschicken" className="button" type="submit" disabled={isSubmitting}>
          Unverbindlich Abschicken
        </button>
        <button aria-label="Zurück" className="button button--ghost-dark" type="button" onClick={stepBack}>
          Zurück
        </button>
      </div>
    </>
  );
}

StepPersonalData.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape(formValueShape).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  stepBack: PropTypes.func.isRequired,
};

// StepPersonalData.defaultProps = {};

export default StepPersonalData;
