import { useState, useEffect } from 'react';
import axios from 'axios';
import { SERVICE, TOKEN } from '../constants/contentful';

const reduceByContentType = (items) => {
  return items.reduce((composed, item) => {
    const contentType = item.sys.contentType.sys.id;
    const dish = item.fields.gericht;
    const current = composed[contentType] ? composed[contentType] : [];

    return {
      ...composed,
      [contentType]: [
        ...current,
        dish,
      ],
    };
  }, {});
};

function useDishes() {
  const [isLoading, setIsLoading] = useState(false);
  const [dishes, setDishes] = useState(null);

  const fetchDishes = async () => {
    setIsLoading(true);
    const { data } = await axios.get(`${SERVICE}/entries?access_token=${TOKEN}`);
    const { items } = data;
    setDishes(reduceByContentType(items));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDishes();
  }, []);

  return [dishes, isLoading];
}

export default useDishes;
