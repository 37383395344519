import React from 'react';
// import PropTypes from 'prop-types';
import { Formik } from 'formik';
import useState from '../../hooks/state';
import StepMenu from '../../presentational/MenuForm/StepMenu';
import StepEventData from '../../presentational/MenuForm/StepEventData';
import StepPersonalData from '../../presentational/MenuForm/StepPersonalData';
import handleSubmit from './submit';

export const scroll = () => {
  setTimeout(() => {
    const element = document.getElementById('menuFormSection');
    const offsetPosition = element.offsetTop - 100;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }, 200);
};

const INITIAL_VALUES = {
  meatDishes: [],
  barbecue: [],
  sideDishes: [],
  salads: [],
  fingerFood: [],
  soups: [],
  desserts: [],
  persons: '',
  date: null,
  withDishes: '',
  delivery: '',
  notes: '',
  name: '',
  street: '',
  zip: '',
  email: '',
  phone: '',
  datenschutz: false,
  bot: '',
};

function MenuForm() {
  const { currentView, setView, isSubmitted, setSubmitted } = useState('menuForm');
  const stepBack = (step) => () => {
    setView(step);
    scroll();
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={async (data) => {
        // const { setSubmitting } = actions;
        // console.log(data, actions);

        switch (currentView) {
          case 'MENU':
            setView('EVENT_DATA');
            scroll();
            break;
          case 'EVENT_DATA':
            setView('PERSONAL_DATA');
            scroll();
            break;
          case 'PERSONAL_DATA':
            await handleSubmit(data, setSubmitted);
            break;
          default:
        }
      }}
    >
      {(formikProps) => (
        <form
          onSubmit={formikProps.handleSubmit}
          className={formikProps.isSubmitting || isSubmitted ? 'form--isSubmitting' : null}
        >
          {currentView === 'MENU' && (<StepMenu {...formikProps} />)}
          {currentView === 'EVENT_DATA' && (<StepEventData {...formikProps} stepBack={stepBack('MENU')} />)}
          {currentView === 'PERSONAL_DATA' && (<StepPersonalData {...formikProps} stepBack={stepBack('EVENT_DATA')} />)}
        </form>
      )}
    </Formik>
  );
}

// MenuForm.propTypes = {};

// MenuForm.defaultProps = {};

export default MenuForm;
