import axios from 'axios';
import moment from 'moment';

const SERVICE_HOST = 'https://c8zvcxmjl3.execute-api.eu-central-1.amazonaws.com/master/order';

function makeUrlData(payload) {
  const { meatDishes, barbecue, sideDishes, salads, fingerFood, soups, desserts, persons, date, withDishes, delivery } = payload;
  const urlPayload = { meatDishes, barbecue, sideDishes, salads, fingerFood, soups, desserts, persons, date, withDishes, delivery };
  return encodeURIComponent(JSON.stringify(urlPayload));
}

function transformData(payload) {
  const { sideDishes, fingerFood, desserts, date, ...rest } = payload;
  return {
    ...rest,
    date: moment(date).format('DD.MM.YYYY'),
    siteDishes: sideDishes,
    fingerfood: fingerFood,
    disserts: desserts,
    customItems: [],
  };
}

async function handleSubmit(payload, setSubmitted) {
  try {
    const { data } = await axios.post(SERVICE_HOST, transformData(payload));
    console.log(data);
    setSubmitted(true);
    window.location.href = `/anfrage-versendet?order=${makeUrlData(payload)}`;
  } catch (error) {
    console.log(error);
  }
}

export default handleSubmit;
