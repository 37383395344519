import React from 'react';
import PropTypes from 'prop-types';
import styles from './MenuSelect.module.scss';

const sortDishes = (dishes) => dishes.sort((a, b) => {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
});

const addItem = (dishes, item) => [
  ...dishes,
  item,
];

const removeItem = (dishes, item) => dishes.filter(
  (dish) => dish !== item,
);

function MenuSelect({
  name,
  onChange,
  value,
  dishes,
  lables,
}) {
  const sortedDishes = sortDishes(dishes);

  return (
    <>
      <div className="form__field">
        <label className="form__label">{lables.majority}</label>

        <div className={styles.box}>
          {sortedDishes.length > 0 && sortedDishes.map((dish) => {
            const selected = value.includes(dish);
            return (
              <button
                key={dish}
                aria-label={`${dish} ${selected ? 'entfernen' : 'hinzufügen'}`}
                className={`button button--secondary ${styles.dish}${selected ? ` ${styles.selected} button--active` : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  onChange(
                    name,
                    selected
                      ? removeItem(value, dish)
                      : addItem(value, dish),
                  );
                }}
              >
                {dish}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}

MenuSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  dishes: PropTypes.arrayOf(PropTypes.string).isRequired,
  lables: PropTypes.shape({
    singular: PropTypes.string.isRequired,
    majority: PropTypes.string.isRequired,
  }).isRequired,
};

MenuSelect.defaultProps = {};

export default MenuSelect;
