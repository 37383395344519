import React from 'react';
import PropTypes from 'prop-types';
import useState from '../../hooks/state';
import { scroll } from '../../container/MenuForm/MenuForm';
import './FormSteps.scss';

const FormSteps = ({ steps }) => {
  const { currentView, setView } = useState('menuForm');
  const currentIndex = steps.findIndex((step) => step.id === currentView);

  return (
    <div className="FormSteps">
      {steps.map((step, index) => {
        const classNames = ['FormSteps__link'];
        if (currentView === step.id) classNames.push('FormSteps__link--active');
        if (currentIndex > index) classNames.push('FormSteps__link--done');
        if (currentIndex < index) classNames.push('FormSteps__link--disabled');

        return (
          <a
            href="#"
            key={step.id}
            className={classNames.join(' ')}
            onClick={() => {
              if (currentIndex > index) {
                setView(step.id);
                scroll();
              }
            }}
          >
            <div className="FormSteps__box">
              <span className="FormSteps__counter">{index + 1}</span>
            </div>
            <div className="FormSteps__title">{step.label}</div>
          </a>
        );
      })}
    </div>
  );
};

FormSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

FormSteps.defaultProps = {};

export default FormSteps;
