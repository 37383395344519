import React from 'react';
import PropTypes from 'prop-types';
import { validatePersons, validateRequired } from '../../container/MenuForm/validators';
import formValueShape from '../../container/MenuForm/shapes';
import NumberField from '../Fields/Number';
import Select from '../Fields/Select';
import DateTime from '../Fields/DateTime';
import Textarea from '../Fields/Textarea';

function StepMenu(props) {
  const { isSubmitting, stepBack, setFieldValue, values } = props;
  return (
    <>
      <NumberField
        {...props}
        name="persons"
        label="Wie viele Personen erwarten Sie?"
        validate={validatePersons('Die Mindestanzahl an Personen liegt bei 30')}
      />

      <DateTime
        {...props}
        name="date"
        onChange={setFieldValue}
        value={values.date}
        label="Wann findet die Veranstaltung statt?"
        validate={validateRequired('Bitte wählen Sie ein Datum')}
      />

      <Select
        {...props}
        name="withDishes"
        label="Brauchen Sie auch Besteck und Geschirr?"
        validate={validateRequired('Bitte wählen Sie eine Option')}
        options={[
          { value: '', label: 'Bitte wählen' },
          { value: 'Ja', label: 'Ja' },
          { value: 'Nein', label: 'Nein' },
        ]}
      />

      <Select
        {...props}
        name="delivery"
        label="Wie kommt das Essen zu Ihnen?"
        validate={validateRequired('Bitte wählen Sie eine Option')}
        options={[
          { value: '', label: 'Bitte wählen' },
          { value: 'Abholung', label: 'Abholung' },
          { value: 'Lieferung', label: 'Lieferung' },
        ]}
      />

      <Textarea
        {...props}
        name="notes"
        label="Haben Sie noch Anmerkungen"
      />

      <div className="form__controls form__controls--space">
        <button aria-label="Weiter zu den Veranstaltungsdaten" className="button" type="submit" disabled={isSubmitting}>
          Weiter zu den persönlichen Daten
        </button>
        <button aria-label="Zurück" className="button button--ghost-dark" type="button" onClick={stepBack}>
          Zurück
        </button>
      </div>
    </>
  );
}

StepMenu.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape(formValueShape).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  stepBack: PropTypes.func.isRequired,
};

// StepMenu.defaultProps = {};

export default StepMenu;
